<template>

<div class="panel" :class="{'is-offset': offset, 'is-hidden': alwaysHidden, 'is-nohead': noHead, 'is-iconmenu': iconMenu, 'is-empty': !hasConventions, 'is-dark': isAccount, 'is-expanded': isExpanded}" v-if="$loggedin">

	<com-profile class="head-profile" v-if="!window.is.desktop && !noProfile" v-on:toggleAccount="$emit('toggleAccount')" />

	<template v-if="!isAccount">

		<slot></slot>

	</template>

	<com-account v-if="isAccount" />

</div>

</template>

<script>

export default {

	props: ['isAccount', 'iconMenu', 'noHead', 'alwaysHidden', 'noProfile', 'isExpanded', 'offset'],

	components: {
		'com-profile': () => import('./head/Profile'),
		'com-account': () => import('./panel/Account')
	},

	computed: {

		hasConventions: function() {

			return this.$store.getters['session/conventions'].length

		}

	}

}

</script>

<style scoped>

.panel {
	position: fixed;
	user-select: none;
	left: 0px;
	top: var(--head-height);
	bottom: 0px;
	background-color: #4d5b6e;
	width: var(--panel-width);
    overflow-y: auto;
	z-index: 2;
	display: flex;
	flex-direction: column;
	transition: all 100ms ease-in-out;
}

.panel.is-hidden,
.is-device .panel {
	z-index: 5;
}

.panel.is-iconmenu {
	width: 40px;
}

.panel.is-nohead {
	top: 0px;
}

.panel:not(.is-iconmenu).is-hidden,
.is-device .panel:not(.is-iconmenu) {
	left: calc(0px - var(--panel-width));
	transition: all 300ms ease-in-out;
	top: 64px;
	padding-bottom: 10px;
}

.panel.is-dark:not(.is-empty) {
	background-color: #21262e;
}

.panel.is-offset {
	transform: translateX(-320px);
}

.panel.is-hidden:not(.is-iconmenu),
.is-device .panel:not(.is-iconmenu) {
	left: calc(0px - var(--panel-width));
	transition: all 300ms ease-in-out;
	top: 64px;
	padding-bottom: 10px;
}

.panel.is-hidden:not(.is-iconmenu) {
	transition: all 100ms linear;
}

.panel.is-hidden:not(.is-iconmenu) {
	top: 0px;
}

.is-mobile .panel:not(.is-iconmenu) {
	top: 48px;
}

.panel.is-hidden:not(.is-iconmenu).is-expanded,
.is-device .panel:not(.is-iconmenu).is-expanded {
	left: 0px;
}

.is-mobile .panel:not(.is-iconmenu) {
	left: -100%;
}

.is-mobile .panel:not(.is-iconmenu).is-expanded {
	left: 0%;
}

.is-mobile .panel:not(.is-iconmenu) {
	width: 100%;
}

</style>
